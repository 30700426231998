<template>
    <ul v-if="items" :class="`primary-nav__items primary-nav__items--${level}`">
        <li v-if="props.level > 1" :class="`primary-nav__item--back primary-nav__item primary-nav__item--${level}`">
            <button class="primary-nav__link" @click="emit('closeMenu')">
                <AtomsImagesIcon icon="sipkaDoleva" class="mr-2" /><template v-if="props.parentGroupName">{{ $t('Zpět na') }} "{{ props.parentGroupName }}"</template>
            </button>
        </li>
        <li v-if="props.level === 1" class="primary-nav__item primary-nav__item--1 hover:underline">
            <AtomsGlobalLink class="primary-nav__link font-bold" :to="appConfig.primaryNavFirstLink.url" :data-text="appConfig.primaryNavFirstLink.text">{{ appConfig.primaryNavFirstLink.text }}</AtomsGlobalLink>
        </li>
        <li v-for="item in items" :class="[`primary-nav__item primary-nav__item--${level}`, { 'is-open': item.isSubMenuOpen, 'is-hidden': item.isHidden }]" @mouseenter="item.groups?.length > 0 && emit('showOverlay')" @mouseleave="item.groups?.length > 0 && emit('hideOverlay')">
            <AtomsGlobalLink :to="item.page.url" class="primary-nav__link" :data-text="item.name">
                <div class="flex items-center gap-2">
                    <AtomsImagesImage v-if="props.level === 2" class="hidden xl:block flex-shrink self-center min-w-0 m-2 lg:m-0 max-w-[80px] lg:max-w-[100px]" :src="`/img/groups/${item.id}.png`" :alt="props.fullName" loading="lazy" />
                    {{ item.name }}
                </div>
            </AtomsGlobalLink>
            <button v-if="item.groups?.length > 0" class="primary-nav__expand" @click="openMenu(item)">+</button>
            <primaryNavLevel :parent-group-name="item.name" :parent-group-id="item.id" :items="item.groups" :level="props.level + 1" @closeMenu="closeMenu(item)" />
        </li>
        <div v-if="false && props.level === 2 && props.parentGroupId === 1171" class="primary-nav__image-item">
            <AtomsGlobalLink to="/" class="primary-nav__image-item__link" :style="`background-image: url(https://www.sperky4u.eu/img/magazine/208_cover.jpg)`">
                <MoleculesButtonsButton :arrow-right="true">Jak vybrat snubní prsten</MoleculesButtonsButton>
            </AtomsGlobalLink>
        </div>
        <div v-if="false && props.level === 2 && props.parentGroupId === 2" class="primary-nav__image-item">
            <AtomsGlobalLink to="/" class="primary-nav__image-item__link" :style="`background-image: url(https://www.sperky4u.eu/img/magazine/208_cover.jpg)`">
                <MoleculesButtonsButton :arrow-right="true">Jak vybrat snubní prsten</MoleculesButtonsButton>
            </AtomsGlobalLink>
        </div>
    </ul>
</template>
<script setup>

const props = defineProps({
    parentGroupName: String,
    parentGroupId: Number,
    items: Array,
    level: Number
});

const items = ref(props.items?.sort((a, b) => b.priority - a.priority));

const appConfig = useAppConfig();

const emit = defineEmits(['openMenu', 'closeMenu', 'showOverlay', 'hideOverlay']);

const openMenu = (item) => {
    items.value.find(q => q == item).isSubMenuOpen = true;
    items.value.filter(q => q != item).forEach(item => item.isHidden = true);
}

const closeMenu = (item) => {
    items.value.find(q => q == item).isSubMenuOpen = false;
    items.value.filter(q => q != item).forEach(item => item.isHidden = false);
}

/*
$('.primary-nav__menu > .primary-nav__link, .primary-nav__expand, .primary-nav__item--back').click(function (e) {
    let $menu = $(this).parent().closest('.primary-nav__item, .primary-nav__menu');

    $menu.toggleClass('is-open');

    if ($menu.hasClass('is-open')) { // opening

        $('html').addClass('primary-nav--open');

        let headerHeight = $('.header').height() + $('.header').offset().top;
        let menuHeight = (window.innerHeight - headerHeight) + 'px';

        $menu.closest('.is-open .primary-nav__items').scrollTop(0).css('overflow-y', 'hidden'); // restrict the height of all preceeding menus except the main level

        $('.primary-nav__close').css('display', 'flex');
        $menu.children('.primary-nav__items').css('height', menuHeight);
    }
    else // closing
    {
        closeLevel($menu);
        $menu.children('.primary-nav__items').css('height', '');
    }

    return false;
});

function closeLevel($parent) {
    $parent.removeClass('is-open'); // close the current level

    $parent.find('.is-open').each(function () { // close all children levels (as for main menu icon click)
        $(this).removeClass('is-open');
        $(this).find('.primary-nav__items').css('overflow-y', 'auto').css('height', '');
    });

    $parent.closest('.is-open > .primary-nav__items').css('overflow-y', 'auto'); // set the parent menu to be scrollable

    if ($('.primary-nav .is-open').length == 0) // when all menus closed, reset page to be scrollable
    {
        $('html').removeClass('primary-nav--open');
        $('.primary-nav__items').css('height', '');
        $('.primary-nav__close').hide();
    }
}
*/
</script>
