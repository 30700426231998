<template>
    <ClientOnly>
        <component :is="componentType" class="relative" :to="link">
            <AtomsImagesIcon icon="kosik" size="xl" class="lg:text-3xl"></AtomsImagesIcon>
            <span class="hidden lg:inline relative -top-2 -left-2 px-[0.4rem] text-xs lg:text-sm rounded-xl text-white bg-black whitespace-nowrap" v-html="props.totalPrice"></span>
            <span class="inline lg:hidden relative -top-2 -left-2 px-[0.4rem] text-xs lg:text-sm rounded-xl text-white bg-black whitespace-nowrap" v-html="props.totalQuantity"></span>
        </component>
        <template #fallback>
            <span class="relative">
                <AtomsImagesIcon icon="kosik" size="3xl" class="lg:4xl text-conversion"></AtomsImagesIcon>
            </span>
        </template>
    </ClientOnly>
</template>
<script setup>

const props = defineProps({
    totalPrice: String,
    totalQuantity: Number
});

const link = computed(() => props.totalPrice ? useAppConfig().definedPageUrls.cart : '');

const componentType = computed(() => {
    if (useCart().totalQuantity.value) {
        return resolveComponent('NuxtLink');
    }
    else {
        return 'span';
    }
});

</script>