<template>
  <div>
    <button class="relative" @click="openLikeBox">
      <AtomsImagesIcon icon="oblibene" size="lg" class="lg:text-2xl" :title="$t('Oblíbené produkty')" />
      <span class="relative -top-2 -left-2 px-[0.4rem] text-xs lg:text-sm rounded-xl text-white bg-black">{{ likedItems.length }}</span>
    </button>
    <OrganismsDialogsDialog :isOpen="isLikeBoxOpen" @close="closeLikeBox" class="border-box w-full m-4 lg:w-[90%] lg:my-16" vertical-align="items-start" overflow="overflow-visible">
      <div class="pt-4 pb-12 px-8">
        <AtomsTextHeader :type="1" class="header--with-line">{{ $t('Vaše oblíbené produkty') }}</AtomsTextHeader>
        <OrganismsListsWareList :items="likedItems" type="standard" @like-list-removed="loadItems" list-name="Oblíbené produkty" />
        <div v-if="likedItems.length === 0 && loaded">{{ $t('Prozatím nemáte žádné produkty označené jako oblíbené. Přidávat je můžete pomocí ikonky srdíčka u každého produktu') }}</div>
      </div>
    </OrganismsDialogsDialog>
  </div>
</template>
<script setup>

const locale = useLocale().getLocale();
const utils = useUtils();

const likedItems = useState('likedWareItems', () => []);
const isLikeBoxOpen = ref(false);
const loaded = ref(false);

const openLikeBox = async () => {
  isLikeBoxOpen.value = true;
  utils.setBodyScrollable(false);
}

const closeLikeBox = () => {
  isLikeBoxOpen.value = false;
  utils.setBodyScrollable(true);
}

const loadItems = async () => {

  loaded.value = false;

  const wareIds = useLikedWares().getLikedWareIds();

  if (wareIds.length > 0) {
    likedItems.value = (await $fetch(`/api/wareList?idWares=${wareIds.join(',')}&currencyId=${locale.currencyId}&languageId=${locale.languageId}&take=1000`)).wareList.wares.items;
  }
  else {
    likedItems.value = [];
  }

  loaded.value = true;

}

// reload when liked ware changed
watch(useLikedWares().getLikedWareIds(), () => {
  loadItems();
});

// inital load
if (useLikedWares().getLikedWareIds().length && !likedItems.value.length) {
  loadItems();
}

</script>