<template>
    <div class="mr-8 text-lg">
        <template v-for="(item, index) in locales">
            <button @click="languageSwitched(item.id)" :class="{ 'font-bold': currentLocale === item.id }">
                {{ item.currencySignLocal }}
            </button>
            <span class="mx-2 font-light text-gray" v-if="index < locales.length - 1">|</span>
        </template>
    </div>
</template>
<script setup>

const locales = useLocale().getLocalesForCurrentShopZone();
const currentLocale = useLocale().getLocale().id;

const languageSwitched = (id) => {
    useLocale().setLocale(id);
    document.location = document.location.toString().replace(document.location.hash, '');
}

</script>