<template>
    <AtomsImagesIcon icon="lupa" size="lg" class="lg:text-2xl mr-2 cursor-pointer" @click="openSearch" />
    <OrganismsDialogsDialog :isOpen="isSearchOpen" @close="closeSearch(true)" class="border-box w-full m-4 lg:w-[90%] lg:my-16" vertical-align="items-start" overflow="overflow-visible">
        <div class="pt-24">
            <div class="py-4 bg-secondary">
                <div class="page-container">
                    <input type="search" id="txtSearch" :placeholder="$t('hledat...')" class="min-w-[80px] w-full px-10 py-2 rounded-lg" v-model="searchText" ref="searchTextbox" @keyup.enter="navigateToSearchResults" />
                </div>
            </div>
            <div class="page-container py-10">
                <div v-if="numOfResults > 0 && searchText.length > 2" class="lg:flex gap-10 lg:px-10">
                    <div v-if="searchResults?.groups?.count || searchResults?.infoPages?.count" class="lg:w-1/2 mb-8">

                        <!-- groups -->
                        <div v-if="searchResults?.groups?.count">
                            <div class="uppercase text-gray mb-4">{{ $t('Kategorie') }}</div>
                            <div v-for="item in searchResults.groups.items" class="mb-4">
                                <AtomsGlobalLink :to="item.page.url" @click="closeSearch">{{ item.page.title }}</AtomsGlobalLink>
                            </div>
                        </div>

                        <!-- divider -->
                        <div v-if="searchResults?.groups?.count && searchResults?.infoPages?.count" class="block my-12 border-b-4 max-w-[300px] border-secondary"></div>

                        <!-- infopages -->
                        <div v-if="searchResults?.infoPages?.count">
                            <div class="uppercase text-gray mb-4 mt-8">{{ $t('Články') }}</div>
                            <div v-for="item in searchResults.infoPages.items" class="mb-4">
                                <AtomsGlobalLink :to="item.page.url" @click="closeSearch">{{ item.page.title }}</AtomsGlobalLink>
                            </div>
                        </div>
                    </div>

                    <!-- divider -->
                    <div v-if="(searchResults?.groups?.count || searchResults?.infoPages?.count) && searchResults?.wares?.count" class="block lg:hidden my-12 border-b-4 max-w-[300px] border-secondary"></div>

                    <div class="flex-1">

                        <!-- wares -->
                        <div class="lg:hidden uppercase text-gray mb-6 mt-8">{{ $t('Produkty') }}</div>
                        <OrganismsListsWareList :items="searchResults?.wares?.items" type="search" @click="closeSearch" list-name="Našeptávač" />
                    </div>
                </div>
                <div v-if="numOfResults > 0 && searchText.length > 2" class="flex justify-end">
                    <MoleculesButtonsButton :arrowRight="true" :href="searchUrl" :force-reload="true">Zobrazit všechny výsledky</MoleculesButtonsButton>
                </div>
                <div v-if="searchText.length <= 2" class="text-xl text-gray">
                    {{ $t('Zadejte text pro vyhledání') }}
                </div>
                <div v-if="searchText.length > 2 && numOfResults === 0" class="text-xl text-gray">
                    {{ $t('Nebyly nalezeny žádné výsledky. Upravte prosím vyhledávaný text') }}
                </div>
            </div>
        </div>
    </OrganismsDialogsDialog>
</template>
<script setup>

import { useDebounceFn } from '@vueuse/core'

const locale = useLocale().getLocale();
const appConfig = useAppConfig();
const router = useRouter();
const utils = useUtils();
const analytics = useAnalytics();

const isSearchOpen = useState('isSearchOpen', () => false);

let suggestHasBeenOpenedInPast = false;

const searchText = ref('');
const searchTextbox = ref(null);
const searchResults = ref({});

const numOfResults = computed(() => searchResults.value.groups?.count + searchResults.value.infoPages?.count + searchResults.value.wares?.count);
const searchUrl = computed(() => `${appConfig.definedPageUrls.search}?q=${encodeURIComponent(searchText.value)}`);

const navigateToSearchResults = () => {
    useLoader().show();
    document.location = searchUrl.value;
}

const openSearch = () => {
    isSearchOpen.value = true;
    utils.setBodyScrollable(false);
    searchTextbox.value.focus();

    if (!suggestHasBeenOpenedInPast) {
        analytics.pushEvent('user_interaction', {
            interaction_name: 'search_start'
        });
    }
}

const closeSearch = (withAnalytics) => {
    isSearchOpen.value = false;
    utils.setBodyScrollable(true);

    if (withAnalytics) {
        analytics.pushEvent('search', {
            search_term: searchText.value,
            search_type: 'close',
            search_results: (searchResults.value.wares?.count + searchResults.value.groups?.count + searchResults.value.infoPages?.count) ?? 0,
            search_results_products: searchResults.value.wares?.count ?? 0,
            search_results_groups: searchResults.value.groups?.count ?? 0,
            search_results_articles: searchResults.value.infoPages?.count ?? 0
        });

        analytics.pushEvent('user_interaction', {
            interaction_name: 'search_close'
        });
    }
}

const search = useDebounceFn(async () => {
    if (searchText.value.length > 2) {
        searchResults.value = (await useApiFetch(`/api/search?phrase=${encodeURIComponent(searchText.value)}&take=${appConfig.wareList.searchItems}&currencyId=${locale.currencyId}&languageId=${locale.languageId}`)).data.value.search;

        analytics.pushEvent('search', {
            search_term: searchText.value,
            search_type: 'suggest',
            search_results: (searchResults.value.wares?.count + searchResults.value.groups?.count + searchResults.value.infoPages?.count) ?? 0,
            search_results_products: searchResults.value.wares?.count ?? 0,
            search_results_groups: searchResults.value.groups?.count ?? 0,
            search_results_articles: searchResults.value.infoPages?.count ?? 0
        });
    }
}, 500);

watch(searchText, () => {
    search();
});

</script>