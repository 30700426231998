<template>
    <div class="primary-nav">
        <div class="primary-nav__menu" :class="{ 'is-open': modelValue }">
            <button class="primary-nav__link" @click="emit('update:modelValue', !props.modelValue)">
                <AtomsImagesIcon icon="burger" class="mr-2" /> Kategorie
            </button>
            <MoleculesBlocksPrimaryNavLevel :items="groups" :level="1" @showOverlay="emit('update:modelValue', true)" @hideOverlay="emit('update:modelValue', false);" />
        </div>
    </div>
</template>
<script setup>

const props = defineProps({
    modelValue: Boolean
});

const locale = useLocale().getLocale();

if (!useState('groups').value) {
    useState('groups').value = (await useApiFetch(`/api/groups?languageId=${locale.languageId}&currencyId=${locale.currencyId}`)).data.value.groups;
}

const groups = useState('groups').value;

const emit = defineEmits(['update:modelValue']);

</script>
<script>
/*
$(window).resize(function () {
    if (!window.matchMedia('(max-width: 1024px)').matches) {
        closeLevel($('.primary-nav__menu.is-open'));
    }
});

$('.primary-nav__close').click(function () {
    closeLevel($('.primary-nav__menu.is-open'));
    $('.header').removeClass('header--with-search');
    $(this).hide();
    hideSearch();
});
*/


</script>
<style lang="scss">
$hover-transition-time: 0.1s;

$window-padding-sm: 20px;

$screen-lg: 1920px;
$screen-md: 1366px;
$screen-sm: 1024px;
$screen-min-sm: 1025px;

$color-background: white;

$color-text-primary: #333333;
$color-text-inverted: white;

$color-text-secondary: #ffcad4;

$color-conversion-primary: #b54761;
$color-conversion-primary-hover: #b54761;
$color-conversion-secondary: #999999;
$color-conversion-secondary-hover: #333333;

$color-gray: #999999;

@mixin media-lg {
    @media (max-width: $screen-lg) {
        @content
    }
}

@mixin media-md {
    @media (max-width: $screen-md) {
        @content
    }
}

@mixin media-sm {
    @media (max-width: $screen-sm) {
        @content
    }
}

@mixin media-min-sm {
    @media (min-width: $screen-min-sm) {
        @content
    }
}

@keyframes header-menu {
    0% {
        pointer-events: none;
    }

    100% {
        pointer-events: all;
    }
}

.primary-nav {
    z-index: 2001;

    &__items--1 {
        /* remains the same for all media queries */
        display: flex;
        list-style-type: none;
        padding: 0 1em;
        margin: 0;
        left: 0 !important;

        @include media-min-sm {
            height: auto !important;
        }
    }

    &__items--2 {
        /* mobile-first */
        position: initial;
        border: 0;
        padding-top: 0;

        /* applies only on desktop screens so it doesn´t affect mobile inheritance */
        @include media-min-sm {
            position: absolute;
            left: 5rem;
            right: 5rem;
            top: 100%;
            z-index: 1000;
            display: flex;
            flex-wrap: wrap;
            border-top: 0;
            padding-left: 35px;
            padding-right: 35px;
            height: 0;
            max-height: 70vh;
            overflow: hidden;
            background-color: $color-background;
            transition: opacity 0.25s ease, pointer-events 0.3s;
            pointer-events: none;
            transition-delay: .3s;
            opacity: 0;
            border-top: 2px solid $color-text-secondary;
            box-shadow: 0 10px 20px #dddddd;
        }
    }

    &__items--3 {

        /* applies only on desktop screens so it doesn´t affect mobile inheritance */
        @include media-min-sm {
            display: none;
            padding: 0;
            margin-top: 0.5em;
            line-height: 1.5em;

            & .primary-nav__item {
                display: none;
            }

            & .primary-nav__item:nth-of-type(2),
            & .primary-nav__item:nth-of-type(3),
            & .primary-nav__item:nth-of-type(4),
            & .primary-nav__item:nth-of-type(5) // first is the back link

                {
                display: list-item;
            }
        }
    }

    &__items--4 {

        /* don´t show deeper levels on desktop */
        @include media-min-sm {
            display: none;
        }
    }

    &__items {

        /* default mobile-first "explorer" list */
        @include media-sm {
            position: absolute;
            left: 100%;
            top: 100%;
            width: 100%;
            height: 0;
            padding: 0;
            overflow: hidden;
            background-color: $color-background;
            --transition: height 0.25s ease, left 0.25s ease;
        }

        @include media-min-sm {
            top: 100% !important;
        }
    }

    &__item--1 {

        @include media-min-sm {

            display: flex;
            align-items: center;

            &:hover {

                &>.primary-nav__link {
                    font-weight: bold;
                }

                /* open the pane on the desktop screens */
                &>.primary-nav__items {
                    height: auto;
                    overflow: auto;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    z-index: 1001;
                    opacity: 1;
                    animation: header-menu 0.3s;
                    animation-fill-mode: forwards;
                }
            }

            &>.primary-nav__link {

                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                text-transform: uppercase;
                padding: 1rem;
                padding-bottom: 0.75rem;
                display: flex;
                text-align: center;
                transition: all $hover-transition-time ease;

                & .icon {
                    margin-right: 0.8em;
                }

                &::after {
                    content: attr(data-text);
                    content: attr(data-text) / "";
                    height: 0;
                    visibility: hidden;
                    overflow: hidden;
                    user-select: none;
                    pointer-events: none;
                    font-weight: bold;
                }
            }
        }
    }

    &__item--2 {
        @include media-min-sm {
            width: calc(33% - 1px);
            @apply p-4 transition-all;
            
            &:hover {
                @apply shadow-around;
            }

            &>.primary-nav__link {
                font-size: 1.2em;
                font-weight: bold;
                font-family: "Crimson Text", serif;
            }
        }
    }

    &__item--3 {
        @include media-min-sm {
            &>.primary-nav__link {
                font-size: 0.9rem;
                color: $color-conversion-secondary;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__item {
        background-size: 0;

        @include media-sm {
            display: flex;
            border-bottom: 1px dotted $color-gray;
            padding-left: $window-padding-sm;
            padding-right: $window-padding-sm;

            &:first-of-type {
                border-top: 0;
            }

            &>.primary-nav__link {
                display: flex;
                flex: 1;
                align-items: center;
                text-align: left;
                text-transform: none;
                padding-left: 0;
                padding-right: 0;
                font-size: 1em;
                min-height: 50px;

                &:hover {
                    color: $color-conversion-primary;
                    background-color: transparent;
                    font-weight: bold;
                }
            }
        }

        &--back {
            display: none;

            @include media-sm {
                display: flex;
                background-position-x: calc(100% - 20px);

                & .primary-nav__link {
                    padding-left: 0;
                    color: $color-conversion-secondary;

                    &:hover {
                        color: $color-conversion-secondary-hover;
                    }

                    & .icon {
                        transform: rotate(180deg);
                        margin-right: 20px;
                    }
                }
            }

            &.primary-nav__item--1 {
                display: none;
            }
        }
    }

    &__more {
        display: inline-block;
        margin-top: 0.3em;

        @include media-sm {
            display: none;
        }
    }

    &__expand {
        display: none;
        align-items: center;
        justify-content: center;
        width: 50px;
        padding-left: 20px;
        font-weight: bold;
        font-size: 1.8em;
        border-left: 1px dotted $color-gray;

        @include media-sm {
            display: flex;
        }
    }

    &__close {
        display: none;
        cursor: pointer;
        flex: 1;
        align-items: center;
        justify-content: flex-end;

        @include media-min-sm {
            display: none !important;
        }
    }

    &__wrapper {
        display: flex;

        &.page-container {
            padding: 0;

            @include media-sm {
                padding-left: $window-padding-sm;
                padding-right: $window-padding-sm;
            }
        }
    }

    &__menu,
    &__search {
        line-height: 18px;

        &>.primary-nav__link {
            display: none;

            @include media-sm {
                display: flex;
                cursor: pointer;
                text-decoration: none;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0.5em 1.5em;
                padding-top: 0;
                margin-left: -1.5rem;
                text-align: left;
                align-items: center;
                transition: all $hover-transition-time ease;

                & .icon {
                    margin-right: 0.8em;
                }
            }
        }
    }

    &__image-item {

        max-width: 350px;

        &__link {
            display: flex;
            padding: 2rem;
            aspect-ratio: 1 / 1;
            background-size: cover;
            background-position: center center;

            & .button {
                margin-top: auto;
            }
        }

        @include media-sm {
            max-width: initial;
        }
    }
}

.is-hidden {
    display: none;
}

.is-open {

    &>.primary-nav__link {
        /* styling for the burger icon */
        color: $color-text-primary !important;
        background-color: $color-background !important;
    }

    &>.primary-nav__items {

        /* show the first successing list within the open container */
        @include media-sm {
            display: block;
            position: absolute;
            left: 0;
            top: 100%;
            height: initial;
            z-index: 1000;
            overflow: initial;
        }

        /* nested menus should start at the same point as their parents */
        & .primary-nav__items {
            @include media-sm {
                top: 0;
            }
        }
    }
}

.primary-nav--open {
    .page-wrapper {
        display: none;
    }
}
</style>