<template>
  <header class="relative z-40 bg-white">
    <div v-if="useShopCookie().read('cacheDisabled')" class="p-2 bg-warning text-white text-center">API cache disabled</div>
    <div class="page-container flex gap-1 lg:gap-4 justify-between pt-2 pb-6 !pl-2 !pr-1">
      <div class="header__contact">
        <a :href="`tel:${appConfig.contactPhone}`">
          <AtomsImagesIcon icon="phone" size="2xl" class="mr-4" /><span class="text-sm xl:text-base">{{ appConfig.contactPhone.replaceAll(' ', '&nbsp;') }}</span>
          <span class="ml-4 font-light">Po-Pá 8-16:00</span>
        </a>
      </div>
      <AtomsGlobalLink to="/" class="flex mr-4 items-center">
        <AtomsImagesImage alt="SnubníPrsteny4u.eu" src="/img/logo.png" class="max-h-[50px] sm:max-w-[300px] xl:max-w-[420px] xl:max-h-[100px]" />
      </AtomsGlobalLink>
      <div class="flex items-center gap-2 lg:gap-4" :class="{ 'invisible' : props.isLight}">
        <MoleculesGlobalLocaleSwitch class="hidden lg:block"/>
        <MoleculesGlobalSearchBox />
        <MoleculesGlobalLikeBox />
        <MoleculesGlobalCartIcon :total-price="useCart().prices.value.itemsTotal.formattedValue" :total-quantity="useCart().totalQuantity.value" />
      </div>
    </div>
    <div v-if="!props.isLight" class="border-b-2 border-secondary">
      <div class="page-container relative flex items-center justify-center gap-4">
        <MoleculesGlobalPrimaryNav v-model="isMenuOpen" />
      </div>
    </div>
  </header>
  <AtomsGlobalOverlay :isVisible="isOverlayVisible" @click="closeMenus" />
</template>
<script setup>

const props = defineProps({
  isLight: Boolean
});

const appConfig = useAppConfig();

const isMenuOpen = ref(false);
const isOverlayVisible = computed(() => isMenuOpen.value);

const closeMenus = () => {
  isMenuOpen.value = false;
}

</script>
<style lang="postcss">
.header {
  &__contact {
    @apply hidden lg:flex gap-4 items-center xl:gap-6;

    a {
      @apply font-bold text-gray;


      &::after {
        content: '';
        @apply ml-4 xl:ml-6 border-r-2 border-secondary;
      }

      &:last-of-type::after {
        @apply hidden;
      }
    }
  }
}
</style>